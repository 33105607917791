import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import HomeIcon from "@mui/icons-material/Home";
import InfoIcon from "@mui/icons-material/Info";
import SettingsIcon from "@mui/icons-material/Settings";
import PeopleIcon from "@mui/icons-material/People";
import DateRangeIcon from "@mui/icons-material/DateRange";
import TimerOutlinedIcon from "@mui/icons-material/TimerOutlined";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import ProfileButton from "./ProfileButton";
import "./Navbar.css";
import {
  Link,
  useLocation,
  useHistory,
  Switch,
} from "react-router-dom";
import Employee from "../../Pages/Employee";
import Dashboard from "../../dashboard/Dashboard";
import OnboardingForm from "../OnboardingForm";
import EmployeeDetail from "../../Pages/EmployeeDetail";
import Information from "../../Pages/Information";
import Registered from "../../Pages/Registered";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { TailSpin } from "react-loader-spinner";
import BookingList from "../../Pages/BookingList";
import CRMLeads from "../../Pages/CRMLeads";
import AddLead from "../CRM/Leads/AddLead";
import CRMCompany from "../../Pages/CRMCompany";
import CRMContact from "../../Pages/CRMContact";
import Menu from "../../Pages/Menu";
import AddCompany from "../CRM/Companies/AddCompany";
import AddContact from "../CRM/Contacts/AddContact";
import Inventory from "../../Pages/Inventory";
import Shifts from "../../Pages/Shifts";
import Schedule from "../../Pages/Schedule";
import Sales from "../../Pages/Sales";
import Orders from "../../Pages/Orders";
import Batches from "../../Pages/Batches";
import Returns from "../../Pages/Returns";
import UserOnboardingForm from "../User/UserOnboardingForm";
import Branches from "../User/Branches/Branches";
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import Inactive from "../../Pages/Inactive";
import ProtectedRoutes from "../ProtectedRoutes";
import Settings from "../../Pages/Settings";
import NewPassword from "../../Pages/NewPassword";
import FirstLogin from "../FirstLogin";
import { ArrowBackIos, Campaign, Devices, NotificationsNone } from "@mui/icons-material";
import Announcements from "../../Pages/Announcements";
import Kiosk from "../../Pages/Kiosk";
import NotificationPage from "../../Pages/NotificationsPage";
import zIndex from "@mui/material/styles/zIndex";


const drawerWidth = 200;



const openedMixin = (theme, ismobile=false) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
  ...(ismobile && {
    position: "fixed",
    zIndex: 999,
  })
});

const closedMixin = (theme, ismobile=false) => ({
  zIndex: 999,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: "0",
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
  ...(ismobile && {
    position: "fixed"
  })
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));


const selectedStyle = {
  backgroundColor: "primary.main",
  color: "#000",
};
const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open, ismobile }) => ({
  zIndex: 1000,
  backgroundColor: `#000000b9 !important`,
  borderRadius: "20px",
  border: "2px solid #474d56",
  boxShadow: "0px 0px 10px 0px #00000073",
  backdropFilter: "blur(10px)",
  // transition: theme.transitions.create(["width", "margin"], {
  //   easing: theme.transitions.easing.sharp,
  //   duration: theme.transitions.duration.leavingScreen,
  // }),
  position: "fixed",
  top: "2px",
  width: "80vw",
  margin: "0 auto",
  marginBottom: "10px",
  // left: `calc(50% + ${drawerWidth}px)`,
  //50 percent + closed drawer width
  left: `calc(50% + 40px)`,
  transform: "translateX(-50%)",
  ...(open && {
    margin: "auto",
    left: "unset", // Override left
    transform: "unset", // Remove centering transform
    left: `calc(${drawerWidth}px + 10px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),

  ...(ismobile && {
    width: "100vw",
    top: 0,
    left: 0,
    transform: "unset",
    borderRadius: 0,
    border: "none",
    borderBottom: "2px solid #474d56",
    //no transiotion
    transition: "none",
  }),
}));



//make css style class for list selected item



const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open, ismobile }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme, ismobile),
    "& .MuiDrawer-paper": openedMixin(theme, ismobile),
    transition: "all 0.2s",
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme, ismobile),
    //diplay none for list item text
    "& .MuiListItemText-root": {
      display: "none",
    },
    //display none for list item icon
    "& .MuiListItemIcon-root": {
      margin: "5px 5px",
    },
    ...(ismobile && {
      transition: "none",
  })
  }),


}));

export default function MiniDrawer() {
  const theme = useTheme();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [preload, setPreLoading] = useState(true);
  // const isMobile = window.innerWidth < 768;
  const [open, setOpen] = React.useState(!isMobile);
  // getting logged in user info
  const userLogin = useSelector((state) => state.userLogin);
  const { error, loading } = userLogin;
  const history = useHistory();
  const location = useLocation();
  const currentPath = location.pathname;
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const selectedNavItem = {
    backgroundColor: `${theme.palette.primary.main} !important`,
    color: "#000",
    borderRadius: "20px",
    transition: "all 0.5s",
    width: "100%",
  };

  function truncateBranchName(comapnyName, branchName) {
    //if character length is greater than 20
    let cmpName;
    let brnchName;
    if (comapnyName.length > 10) {
      cmpName = comapnyName.substring(0, 5) + "...";
    }
    else {
      cmpName = comapnyName;
    }
    if (branchName.length > 10) {
      brnchName = branchName.substring(0, 5) + "...";
    }
    else {
      brnchName = branchName;
    }



    return `${cmpName} - ${brnchName}`;
  }


  window.addEventListener("resize", () => {
    const ismobile = window.innerWidth < 768;
    if (ismobile !== isMobile) setIsMobile(ismobile);
    sessionStorage.setItem("isMobile", ismobile);
  });

  const handleDrawerOpen = () => {
    if (open) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  };



  useEffect(() => {
    if (userInfo.isAdmin && userInfo.flag === 'Signed Up') {
      history.push('/user/onboard')
    }
    if (userInfo.flag === "Email Sent" || userInfo.flag === "Registered") {
      // history.push("/onboard");
      if (userInfo.noOfBranches === 1) {
        history.push('/onboard')
      } else if (userInfo.noOfBranches > 1 && location.pathname !== '/branches') {
        history.push('/onboard')
      }
    }
    if (userInfo.flag === "Onboarding") {
      // history.push("/registered");
      if (userInfo.noOfBranches === 1) {
        history.push('/registered')
      } else if (userInfo.noOfBranches > 1 && location.pathname !== '/branches') {
        history.push('/registered')
      }
    }
    // if (userInfo.flag === 'Active' && !userInfo.isAdmin && userInfo.noOfBranches === 1) {
    //   history.push(`/employee/${userInfo.employeeId}`);
    // }
    if (userInfo.flag === 'Inactive') {

      if (userInfo.noOfBranches > 1 && location.pathname !== '/branches') {
        alert('Your account is inactive for this branch')
        history.push('/branches')
      }
      if (userInfo.noOfBranches === 1 && location.pathname !== '/inactive') {
        history.push('/inactive')
      }
    }
    setTimeout(() => {
      setPreLoading(false);
    }, 1000);
  }, [
    userInfo.flag,
    userInfo.isAdmin,
    userInfo.noOfBranches,
    location.pathname,
    history,
  ]);



  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" open={open} ismobile={isMobile}>
        <Toolbar
          sx={{
            backgroundcolor: theme.palette.text.primary,
            color: "white",
          }}
        >

          {
            open ?
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                sx={{
                  marginRight: "10px",
                }}
              >
                <ArrowBackIos />

              </IconButton> :
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                sx={{
                  marginRight: "10px",
                }}
              >
                <MenuIcon />
              </IconButton>

          }
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div>
              {!preload && (
                <Typography
                  variant="h6"
                  noWrap
                  component="div"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  {userInfo.branchLogo &&

                    <img
                      src={userInfo.branchLogo}
                      style={{ width: "4rem", height: "4rem", marginRight: "1rem" }}
                      alt="logo"
                    />
                  }
                  {/* {userInfo.branchName ? <span>{userInfo.companyName} - {userInfo.branchName}</span> : <span>{" "}Please create a branch</span>} */}
                  {userInfo.branchName ? <span>

                    {isMobile ? truncateBranchName(userInfo.companyName, userInfo.branchName) : userInfo.companyName + " - " + userInfo.branchName}

                  </span> : <span>{" "}Please create a branch</span>}
                </Typography>
              )}
            </div>
            {/* <h5>User</h5> */}
            <div>
              <ProfileButton preload={preload} isMobile={isMobile} />
            </div>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open} className="navbar-list"
        ismobile={isMobile}
        PaperProps={{
          style: {
            backgroundColor: "#343537",
            boxShadow: "inset 0px 0px 10px 0px #101010a9",
          }
        }}
      >
        <DrawerHeader className="drawer-header">


        </DrawerHeader>
        <Divider />
        {!preload && userInfo.isAdmin && (
          <>
            <List sx={{ paddingRight: "5px" }}>
              <h3
                style={{
                  ...(!open && { display: "none" }),
                  marginTop: "0",
                  marginBottom: "0",
                  marginLeft: "0.7rem",
                }}
              >
                My Company
              </h3>
              <Link
                exact="true"
                to="/"
                style={{ textDecoration: "none", color: theme.palette.text.primary }}>
                <ListItem
                  button
                  style={{ paddingTop: "0", paddingBottom: "0", backgroundColor: "inherit" }}
                  sx={currentPath === "/" ? selectedNavItem : {}}
                >
                  <ListItemIcon>
                    <HomeIcon sx={{ color: currentPath === "/" ? theme.palette.text.secondary : theme.palette.primary.main }} />
                  </ListItemIcon>
                  <ListItemText primary="Dashboard" />
                </ListItem>
              </Link>
              <Link
                to="/company"
                style={{ textDecoration: "none", color: theme.palette.text.primary }}
              >
                <ListItem
                  button
                  style={{ paddingTop: "0", paddingBottom: "0", backgroundColor: "inherit" }}
                  sx={currentPath === "/company" ? selectedNavItem : {}}
                >
                  <ListItemIcon>
                    <InfoIcon sx={{ color: currentPath === "/company" ? theme.palette.text.secondary : theme.palette.primary.main }} />
                  </ListItemIcon>
                  <ListItemText primary="Information" />
                </ListItem>
              </Link>
              <Link
                to="/branches"
                style={{ textDecoration: "none", color: theme.palette.text.primary }}
              >
                <ListItem
                  button
                  style={{ paddingTop: "0", paddingBottom: "0", backgroundColor: "inherit" }}
                  sx={currentPath === "/branches" ? selectedNavItem : {}}
                >
                  <ListItemIcon>
                    <AccountTreeIcon sx={{ color: currentPath === "/branches" ? theme.palette.text.secondary : theme.palette.primary.main }} />
                  </ListItemIcon>
                  <ListItemText primary="Branches" />
                </ListItem>
              </Link>
              <Link
                to="/settings"
                style={{ textDecoration: "none", color: theme.palette.text.primary }}
              >
                <ListItem
                  button
                  style={{ paddingTop: "0", paddingBottom: "0", backgroundColor: "inherit" }}
                  sx={currentPath === "/settings" ? selectedNavItem : {}}
                >
                  <ListItemIcon>
                    <SettingsIcon sx={{ color: currentPath === "/settings" ? theme.palette.text.secondary : theme.palette.primary.main }} />
                  </ListItemIcon>
                  <ListItemText primary="Settings" />
                </ListItem>
              </Link>
              <Link to="/kiosk" style={{ textDecoration: "none", color: theme.palette.text.primary }}>
                <ListItem
                  button
                  style={{ paddingTop: "0", paddingBottom: "0", backgroundColor: "inherit" }}
                  sx={currentPath === "/kiosk" ? selectedNavItem : {}}
                >
                  <ListItemIcon>
                    <Devices sx={{ color: currentPath === "/kiosk" ? theme.palette.text.secondary : theme.palette.primary.main }} />
                  </ListItemIcon>
                  <ListItemText primary="Kiosk" />
                </ListItem>
              </Link>

              <Link to="/announcement" style={{ textDecoration: "none", color: theme.palette.text.primary }}>
                <ListItem
                  button
                  style={{ paddingTop: "0", paddingBottom: "0", backgroundColor: "inherit" }}
                  sx={currentPath === "/announcement" ? selectedNavItem : {}}
                >
                  <ListItemIcon>
                    <Campaign sx={{ color: currentPath === "/announcement" ? theme.palette.text.secondary : theme.palette.primary.main }} />
                  </ListItemIcon>
                  <ListItemText primary="Announcement" />
                </ListItem>
              </Link>

            </List>
            {!open && <Divider />}
            <List sx={{ paddingRight: "5px" }}>
              <h3
                style={{
                  ...(!open && { display: "none" }),
                  marginTop: "0",
                  marginBottom: "0",
                  marginLeft: "0.7rem",
                }}
              >
                Employees
              </h3>
              <Link

                to="/employee"
                style={{ textDecoration: "none", color: theme.palette.text.primary }}
              >
                <ListItem
                  button
                  style={{ paddingTop: "0", paddingBottom: "0", backgroundColor: "inherit" }}
                  sx={currentPath === "/employee" ? selectedNavItem : {}}
                >
                  <ListItemIcon>
                    <PeopleIcon sx={{ color: currentPath === "/employee" ? theme.palette.text.secondary : theme.palette.primary.main }} />
                  </ListItemIcon>
                  <ListItemText primary="Employees" />
                </ListItem>
              </Link>
              <Link
                to="/employees/schedule"
                style={{ textDecoration: "none", color: theme.palette.text.primary }}
              >
                <ListItem
                  button
                  style={{ paddingTop: "0", paddingBottom: "0", backgroundColor: "inherit" }}
                  sx={currentPath === "/employees/schedule" ? selectedNavItem : {}}
                >
                  <ListItemIcon>
                    <DateRangeIcon sx={{ color: currentPath === "/employees/schedule" ? theme.palette.text.secondary : theme.palette.primary.main }} />
                  </ListItemIcon>
                  <ListItemText primary="Schedule" />
                </ListItem>
              </Link>
              <Link
                to="/employees/shift"
                style={{ textDecoration: "none", color: theme.palette.text.primary }}
              >
                <ListItem
                  button
                  style={{ paddingTop: "0", paddingBottom: "0", backgroundColor: "inherit" }}
                  sx={currentPath === "/employees/shift" ? selectedNavItem : {}}
                >
                  <ListItemIcon>
                    <TimerOutlinedIcon sx={{ color: currentPath === "/employees/shift" ? theme.palette.text.secondary : theme.palette.primary.main }} />
                  </ListItemIcon>
                  <ListItemText primary="Shifts" />
                </ListItem>
              </Link>
            </List>
            {!open && <Divider />}

          </>
        )}
        <List sx={{ paddingRight: "5px" }}>
          {userInfo && userInfo.isAdmin === false &&
            (userInfo.noOfBranches > 1 ||
              Object.keys(userInfo.permissionData).length !== 0 && (
                userInfo.permissionData.Announcement &&
                (userInfo.permissionData.Announcement.read === true ||
                  userInfo.permissionData.Announcement.write === true)
              )
              || userInfo.permissionData.Kiosk && (
                userInfo.permissionData.Kiosk.read === true ||
                userInfo.permissionData.Kiosk.write === true
              )
            )
            && (
              <>
                <h3
                  style={{
                    ...(!open && { display: "none" }),
                    marginTop: "0",
                    marginBottom: "0",
                    marginLeft: "0.7rem",
                  }}
                >
                  My Company
                </h3>

                {userInfo.noOfBranches > 1 ?
                  <Link to="/branches" style={{ textDecoration: "none", color: theme.palette.text.primary }}>
                    <ListItem
                      button
                      style={{ paddingTop: "0", paddingBottom: "0", backgroundColor: "inherit" }}
                      sx={currentPath === "/branches" ? selectedNavItem : {}}
                    >
                      <ListItemIcon>
                        <AccountTreeIcon sx={{ color: currentPath === "/branches" ? theme.palette.text.secondary : theme.palette.primary.main }} />
                      </ListItemIcon>
                      <ListItemText primary="Branches" />
                    </ListItem>
                  </Link>
                  : null}
                {
                  userInfo.permissionData.Kiosk && (
                    userInfo.permissionData.Kiosk.read === true ||
                    userInfo.permissionData.Kiosk.write === true
                  )
                    ? (
                      <Link to="/kiosk" style={{ textDecoration: "none", color: theme.palette.text.primary }}>
                        <ListItem
                          button
                          style={{ paddingTop: "0", paddingBottom: "0", backgroundColor: "inherit" }}
                          sx={currentPath === "/kiosk" ? selectedNavItem : {}}
                        >
                          <ListItemIcon>
                            <Devices sx={{ color: currentPath === "/kiosk" ? theme.palette.text.secondary : theme.palette.primary.main }} />
                          </ListItemIcon>
                          <ListItemText primary="Kiosk" />
                        </ListItem>
                      </Link>
                    ) : null

                }
                {

                  Object.keys(userInfo.permissionData).length !== 0 && (
                    userInfo.permissionData.Announcement &&
                    (userInfo.permissionData.Announcement.read === true ||
                      userInfo.permissionData.Announcement.write === true)
                  )
                    ? (
                      <Link to="/announcement" style={{ textDecoration: "none", color: theme.palette.text.primary }}>
                        <ListItem
                          button
                          style={{ paddingTop: "0", paddingBottom: "0", backgroundColor: "inherit" }}
                          sx={currentPath === "/announcement" ? selectedNavItem : {}}
                        >
                          <ListItemIcon>
                            <Campaign sx={{ color: currentPath === "/announcement" ? theme.palette.text.secondary : theme.palette.primary.main }} />
                          </ListItemIcon>
                          <ListItemText primary="Announcement" />
                        </ListItem>
                      </Link>
                    ) : null}
              </>
            )}
          {userInfo && userInfo.isAdmin === false && Object.keys(userInfo.permissionData).length === 0 && (

            <Link to={`/employee/${userInfo.employeeId}`} style={{ textDecoration: "none", color: theme.palette.text.primary }}>
              <ListItem
                button
                style={{ paddingTop: "0", paddingBottom: "0", backgroundColor: "inherit" }}
                sx={currentPath === `/employee/${userInfo.employeeId}` && selectedNavItem}
              >
                <ListItemIcon>
                  <PeopleIcon sx={{ color: currentPath === `/employee/${userInfo.employeeId}` ? theme.palette.text.secondary : theme.palette.primary.main }} />
                </ListItemIcon>
                <ListItemText primary="My Info" />
              </ListItem>
            </Link>

          )}
          {userInfo && userInfo.isAdmin === false && Object.keys(userInfo.permissionData).length !== 0 && userInfo.noOfBranches && (
            <>
              {(userInfo.permissionData.Employee.read ||
                userInfo.permissionData.Employee.write ||
                userInfo.permissionData.Shift.read ||
                userInfo.permissionData.Shift.write ||
                userInfo.permissionData.Schedule.read ||
                userInfo.permissionData.Schedule.write) && (
                  <h3
                    style={{
                      ...(!open && { display: "none" }),
                      marginTop: "0",
                      marginBottom: "0",
                      marginLeft: "0.7rem",
                    }}
                  >
                    Employees
                  </h3>
                )}

              {userInfo.permissionData.Employee.read === true || userInfo.permissionData.Employee.write === true ? (
                <Link to="/employee" style={{ textDecoration: "none", color: theme.palette.text.primary }}>
                  <ListItem
                    button
                    style={{ paddingTop: "0", paddingBottom: "0", backgroundColor: "inherit" }}
                    sx={currentPath === "/employee" ? selectedNavItem : {}}
                  >
                    <ListItemIcon>
                      <PeopleIcon sx={{ color: currentPath === "/employee" ? theme.palette.text.secondary : theme.palette.primary.main }} />
                    </ListItemIcon>
                    <ListItemText primary="Employees" />
                  </ListItem>
                </Link>
              ) :
                (
                  <Link to={`/employee/${userInfo.employeeId}`} style={{ textDecoration: "none", color: theme.palette.text.primary }}>
                    <ListItem
                      button
                      style={{ paddingTop: "0", paddingBottom: "0", backgroundColor: "inherit" }}
                      sx={currentPath === `/employee/${userInfo.employeeId}` && selectedNavItem}
                    >
                      <ListItemIcon>
                        <PeopleIcon sx={{ color: currentPath === `/employee/${userInfo.employeeId}` ? theme.palette.text.secondary : theme.palette.primary.main }} />
                      </ListItemIcon>
                      <ListItemText primary="My Info" />
                    </ListItem>
                  </Link>
                )
              }

              {userInfo.permissionData.Shift.read === true || userInfo.permissionData.Shift.write === true ? (
                <Link to="/employees/shift" style={{ textDecoration: "none", color: theme.palette.text.primary }}>
                  <ListItem
                    button
                    style={{ paddingTop: "0", paddingBottom: "0", backgroundColor: "inherit" }}
                    sx={currentPath === "/employees/shift" ? selectedNavItem : {}}
                  >
                    <ListItemIcon>
                      <TimerOutlinedIcon sx={{ color: currentPath === "/employees/shift" ? theme.palette.text.secondary : theme.palette.primary.main }} />
                    </ListItemIcon>
                    <ListItemText primary="Shifts" />
                  </ListItem>
                </Link>
              ) : null}

              {userInfo.permissionData.Schedule.read === true || userInfo.permissionData.Schedule.write === true ? (
                <Link to="/employees/schedule" style={{ textDecoration: "none", color: theme.palette.text.primary }}>
                  <ListItem
                    button
                    style={{ paddingTop: "0", paddingBottom: "0", backgroundColor: "inherit" }}
                    sx={currentPath === "/employees/schedule" ? selectedNavItem : {}}
                  >
                    <ListItemIcon>
                      <DateRangeIcon sx={{ color: currentPath === "/employees/schedule" ? theme.palette.text.secondary : theme.palette.primary.main }} />
                    </ListItemIcon>
                    <ListItemText primary="Schedule" />
                  </ListItem>
                </Link>
              ) : null}
            </>
          )}
          {
            userInfo && userInfo.isAdmin === false && userInfo.flag === 'Active' && (
              <>
                <Link to={`/employee/notifications/${userInfo.employeeId}`} style={{ textDecoration: "none", color: theme.palette.text.primary }}>
                  <ListItem
                    button
                    style={{ paddingTop: "0", paddingBottom: "0", backgroundColor: "inherit", marginTop: "0.5rem" }}
                    sx={currentPath === `/employee/notifications/${userInfo.employeeId}` && selectedNavItem}
                  >
                    <ListItemIcon>
                      <NotificationsNone sx={{ color: currentPath === `/employee/notifications/${userInfo.employeeId}` ? theme.palette.text.secondary : theme.palette.primary.main }} />
                    </ListItemIcon>
                    <ListItemText primary="Notifications" />
                  </ListItem>
                </Link>

              </>
            )
          }
        </List>
      </Drawer>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 1,
          overflowX: "auto",
          minHeight: "100vh",
          "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            bordercolor: theme.palette.text.primary,
          },
          "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
          {
            borderColor: theme.palette.primary.main,
          },
          "& .MuiOutlinedInput-input": {
            color: theme.palette.text.primary,
          },
          "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
            color: theme.palette.text.primary,
          },
          "& .MuiInputLabel-outlined": {
            color: theme.palette.text.primary,
          },
          "& .MuiInputLabel-outlined.Mui-focused": {
            color: theme.palette.text.primary,
          },
        }}


      >
        <DrawerHeader />
        {
          userInfo.isAdmin === false && userInfo.firstLogin
            //path is not onboard
            && location.pathname !== "/onboard"
            ? (<FirstLogin />) : null
        }
        {preload ? (
          <div className="loader">
            <TailSpin color={theme.palette.primary.main} height={120} width={120} />
          </div>
        ) : (
          <div>
            <Switch>
              <ProtectedRoutes exact path="/" component={Dashboard} />
              <ProtectedRoutes exact path="/settings" component={Settings} />
              <ProtectedRoutes exact path="/employee" component={Employee} />
              <ProtectedRoutes exact path="/employees/shift" component={Shifts} />
              <ProtectedRoutes exact path="/employees/schedule" component={Schedule} />
              <ProtectedRoutes exact path="/employee/:id" component={EmployeeDetail} />
              <ProtectedRoutes exact path="/onboard" component={OnboardingForm} />
              <ProtectedRoutes exact path="/branches" component={Branches} />
              <ProtectedRoutes exact path="/announcement" component={Announcements} />
              <ProtectedRoutes exact path="/kiosk" component={Kiosk} />
              <ProtectedRoutes exact path="/employee/notifications/:id" component={NotificationPage} />
              <ProtectedRoutes exact path="/user/onboard" component={UserOnboardingForm} />
              <ProtectedRoutes exact path="/company" component={Information} />
              <ProtectedRoutes exact path="/registered" component={Registered} />
              <ProtectedRoutes exact path="/inactive" component={Inactive} />
              <ProtectedRoutes exact path="/booking/list" component={BookingList} />
              <ProtectedRoutes exact path="/crm/lead" component={CRMLeads} />
              <ProtectedRoutes exact path="/crm/lead/create" component={AddLead} />
              <ProtectedRoutes exact path="/crm/company" component={CRMCompany} />
              <ProtectedRoutes exact path="/crm/company/create" component={AddCompany} />
              <ProtectedRoutes exact path="/crm/contact" component={CRMContact} />
              <ProtectedRoutes exact path="/crm/contact/create" component={AddContact} />
              <ProtectedRoutes exact path="/menu" component={Menu} />
              <ProtectedRoutes exact path="/inventory" component={Inventory} />
              <ProtectedRoutes exact path="/order/insights/sales" component={Sales} />
              <ProtectedRoutes exact path="/order/list" component={Orders} />
              <ProtectedRoutes exact path="/inventory/batches" component={Batches} />
              <ProtectedRoutes exact path="/order/return" component={Returns} />
            </Switch>
          </div>
        )}


      </Box>
    </Box>
  );
}

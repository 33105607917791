import { AccessTime, Delete, FileCopy, PhotoCamera, Streetview } from '@mui/icons-material';
import { Alert, Autocomplete, Box, Button, Chip, CircularProgress, Collapse, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Select, Skeleton, Slide, Snackbar, TextField, Typography, useTheme } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import AnnouncementStats from '../components/My Company/AnnouncementStats';
import { parseISO } from 'date-fns';
import dayjs from 'dayjs';


const Announcements = () => {
    const [announcements, setAnnouncements] = useState([]);
    const [loading, setLoading] = useState(true);
    const [createDialog, setCreateDialog] = useState(false);
    const [departmentsList, setDepartmentsList] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);
    const [statsBox, setStatsBox] = useState(false);
    const [statsData, setStatsData] = useState(null);
    const [statsBoxLoading, setStatsBoxLoading] = useState(true);
    const [selectedAnnouncement, setSelectedAnnouncement] = useState(null);
    const [fileDialog, setFileDialog] = useState(false);
    const [fileSrc, setFileSrc] = useState(null);
    const [creating , setCreating] = useState(false);

    // const expiryDate = new Date();
    // expiryDate.setDate(expiryDate.getDate() + 7);


    //use dayjs

    const [newAnnouncement, setNewAnnouncement] = useState({
        message: '',
        departments: [],
        expiryDate: dayjs().add(7, 'day').toDate(),
        fileLink: '',
    });

    const handleSelectFile = (e) => {

        if(e.target.files[0].size > 2097152){
            setError('File size should be less than 2 MB.');
            return;
        }
        setSelectedFile(e.target.files[0]);
    }

    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");

    const userLogin = useSelector((state) => state.userLogin);
    const { userInfo } = userLogin;
    const config = {
        headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${userInfo.JWT_TOKEN}`,
        },
    };


    const handleCreateAnnouncementDialog = () => {
        const branchId = userInfo.currentBranchId;
        axios.get(`${process.env.REACT_APP_API_BACKEND}/api/getDepartments/${branchId}`, config).then((res) => {
            setDepartmentsList(res.data);
            setNewAnnouncement((prevAnnouncement) => ({
                ...prevAnnouncement,
                departments: res.data.map((dept) => dept.department),
            }));
            setCreateDialog(true);
        }).catch((err) => {
            console.log(err);
            setError("Error fetching departments.");
        }
        );
    };


    const handleCreateAnnouncement = () => {
        setCreating(true);
        if (newAnnouncement.message === '') {
            setError('Please enter a message.');
            return;
        }

        if (newAnnouncement.departments.length === 0) {
            setError('Please select at least one department.');
            return;
        }
        //file size validation max 2 mb
        if (selectedFile && selectedFile.size > 2097152) {
            setError('File size should be less than 2 MB.');
            return;
        }

        const config = {
            headers: {
                "Content-type": "multipart/form-data",
                Authorization: `Bearer ${userInfo.JWT_TOKEN}`,
            },
            onUploadProgress: (progressEvent) => {
                const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                console.log(`Upload Progress: ${percentCompleted}%`);
                // You can set the progress state here and use it in your UI
                // Example: setUploadProgress(percentCompleted);
            },
        };
        const data = new FormData();
        data.append('message', newAnnouncement.message);
        data.append('expiryDate', newAnnouncement.expiryDate.toISOString());
        data.append('fileLink', selectedFile);
        data.append('departments', JSON.stringify(newAnnouncement.departments.map((dept) => departmentsList.find((department) => department.department === dept).id)));


        axios
            .post(
                `${process.env.REACT_APP_API_BACKEND}/api/announcement/create`,
                data,
                config
            )
            .then((res) => {
                fetchAnnouncements();
                setCreateDialog(false);
                setSuccess('Announcement created successfully.');
                setNewAnnouncement({
                    message: '',
                    departments: [],
                    expiryDate: dayjs().add(7, 'day').toDate(),
                    fileLink: '',
                });
                setSelectedFile(null);
                setCreating(false);
                setTimeout(() => {
                    setSuccess('');
                }
                    , 2500);
            })
            .catch((err) => {
                setError(err.response.data.message);
                setCreating(false);
                setTimeout(() => {
                    setError('');
                }
                    , 2500);
            });

    };


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewAnnouncement((prevAnnouncement) => ({
            ...prevAnnouncement,
            [name]: value,
        }));
    };

    const handleDelteAnnouncement = (id) => {
        axios
            .delete(
                `${process.env.REACT_APP_API_BACKEND}/api/announcement/delete/${id}`,
                config
            )
            .then((res) => {
                fetchAnnouncements();
                //stats box
               if(selectedAnnouncement === id){
                setStatsBox(false);
                }
                setSuccess('Announcement deleted successfully.');
            })
            .catch((err) => {
                console.error(err);
            });
    };

    const handleCancel = () => {
        setCreateDialog(false);
        setNewAnnouncement({
            message: '',
            departments: [],
            expiryDate: dayjs().add(7, 'day').toDate(),
            fileLink: '',
        });
        setSelectedFile(null);
    };

    const fetchAnnouncements = () => {
        axios.get(
            `${process.env.REACT_APP_API_BACKEND}/api/announcements/get`,
            config
        ).then((res) => {
            setAnnouncements(res.data.data);
            setLoading(false);
        }).catch((err) => {
            console.log(err);
        }
        );
    }
    useEffect(() => {
        fetchAnnouncements();
    }
        , []);
    const toLocalTime = (date) => {
        return new Date(date).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    }

    const getStats = (id) => {
        setSelectedAnnouncement(id);
        setStatsBoxLoading(true);
        setStatsBox(true);
        axios.get(
            `${process.env.REACT_APP_API_BACKEND}/api/announcement/stats/${id}`,
            config
        ).then((res) => {
            setStatsData(res.data.data);
            if(statsBox === false){
                setStatsBox(true);
            }
            setStatsBoxLoading(false);

        }).catch((err) => {
            console.log(err);
        }
        );
    }

    const statsBoxClass = statsBox ? 'statsBoxVisible' : 'statsBoxHidden';

    const handleSelectAllDepartments = () => {
        // Select all departments
        setNewAnnouncement((prevAnnouncement) => ({
            ...prevAnnouncement,
            departments: departmentsList.map((dept) => dept.department),
        }));
    };

    const handleRemoveAllDepartments = () => {
        // Remove all selected departments
        setNewAnnouncement((prevAnnouncement) => ({
            ...prevAnnouncement,
            departments: [],
        }));
    };

    const showFile = (fileLink) => {
        setFileSrc(fileLink);
        setFileDialog(true);
    }

    const theme = useTheme();
    return (
        <div style={{padding: "10px", paddingTop: "20px" }}>

            {success &&
                <Snackbar open={true} autoHideDuration={2500} onClose={() => setSuccess("")} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                    <Alert onClose={() => setSuccess("")} severity="success" sx={{ width: '100%' }}>
                        {success}
                    </Alert>
                </Snackbar>
            }
            {error &&
                <Snackbar open={true} autoHideDuration={2500} onClose={() => setError("")} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                    <Alert onClose={() => setError("")} severity="error" sx={{ width: '100%' }}>
                        {error}
                    </Alert>
                </Snackbar>
            }
            {userInfo && userInfo.isAdmin == true ||
                Object.keys(userInfo.permissionData).length !== 0 && (
                    userInfo.permissionData.Announcement &&
                    (
                        userInfo.permissionData.Announcement.write === true)
                ) ?
                <Button
                    onClick={() => handleCreateAnnouncementDialog()}
                    variant="contained" sx={{ margin: "20px 0", color: "#fff", margin: "auto", display: "block", color: theme.palette.text.primary, backgroundColor: theme.palette.button.blue}}
                >
                    Create Announcement
                </Button>
                : null}
            <Box sx={{ display: "flex",  padding: "10px 20px", transition: "all 3s ease-in-out" }}>
                <Box sx={{ width: "max-content", margin: "0 auto", flex: "1",transition: "all 3s ease-in-out"  }}>
                    {
                        loading ?
                            (<Skeleton variant="rect" height={118} />)
                            :
                            (announcements && announcements.length !== 0 ?
                                announcements.map((item, i) => (
                                    <Box key={i}
                                        onClick={() => getStats(item.id)}
                                        sx={{
                                            display: "flex",    
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                            padding: "10px 20px",
                                            borderRadius: "10px",
                                            margin: "10px 0",
                                            cursor: "pointer",
                                            backgroundColor:  theme.palette.card.alt,
                                            color: selectedAnnouncement === item.id && theme.palette.text.primary, 
                                            boxShadow: 'inset 0 0 0 1px #000000ff',
                                            boxShadow: selectedAnnouncement === item.id ? `inset 0 0 0 1px ` : 'inset 0 0 0 1px #000000ff',
                                          }}
                                          >
                                        
                                        <Box>
                                            <Typography sx={{ fontSize: "1.2rem" }}>
                                                {item.message}</Typography>
                                            <Typography sx={{ display: "flex", alignItems: "center", fontWeight: '1.2rem' }}>
                                                <AccessTime sx={{ marginRight: "5px", fontSize: "1rem" }} />
                                                {toLocalTime(item.createdAt)}
                                            </Typography>
                                            {item.createdBy && <Typography sx={{ display: "flex", alignItems: "center" }}>
                                                Created By: {item.createdBy} 
                                            </Typography>}
                                            {
                                                item.departments.map((department, i) => (
                                                    <Chip key={i} label={department} sx={{ margin: "5px 5px 0 0" , 
                                                    backgroundColor: selectedAnnouncement === item.id ? theme.palette.primary.main : null,
                                                    color: selectedAnnouncement === item.id && theme.palette.text.secondary
                                                    }} />
                                                ))
                                            }
                                        </Box>
                                        <Box sx={{ display: "flex", alignItems: "center", width: "max-content", marginRight: "20px" }}>
                                            {item.fileLink &&
                                            <FileCopy onClick={() => {showFile(item.fileLink)}} sx={{ marginRight: "20px", cursor: "pointer" }} />
                                        }
                                            {userInfo && userInfo.isAdmin == true ||
                                                Object.keys(userInfo.permissionData).length !== 0 && (
                                                    userInfo.permissionData.Announcement &&
                                                    (
                                                        userInfo.permissionData.Announcement.write === true)
                                                ) ?
                                                <Delete onClick={() => handleDelteAnnouncement(item.id)} sx={{ cursor: "pointer" }} />
                                                : null}
                                            </Box>
                                    </Box>

                                ))
                                :
                                <h3>No Announcements</h3>
                            )

                    }
                </Box>
                {statsBox === true ?
                    <Box 
                    sx={{
                        width: "max-content",
                        margin: "0 auto",
                        flex: "2",
                        transition: "all 1s ease-in-out",
                        opacity: statsBox ? 1 : 0, // Set initial opacity based on statsBox state
                      }}
                      className={statsBoxClass}
                    >
                        <AnnouncementStats setOpen={setStatsBox} data={statsData} loading={statsBoxLoading} />
                    </Box>
                    :
                    null
                }
            </Box>
            <Dialog open={createDialog} >
                <DialogTitle>Create Announcement</DialogTitle>
                <DialogContent sx={{ width: "500px" }}>
                    <TextField
                        label="Message"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        name="message"
                        value={newAnnouncement.message}
                        onChange={handleInputChange}
                        multiline
                        rows={5}
                        inputProps={{ maxLength: 1000 }}
                        sx={{ '& label': { color: theme.palette.text.primary } }}
                    />

                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker label="Expiry Date" value={
                            parseISO(newAnnouncement.expiryDate)
                        } 
                        format='DD/MM/YYYY'
                        sx={{ '& label': { color: theme.palette.text.primary } }}
                        onChange={(newValue) => {
                            setNewAnnouncement((prevAnnouncement) => ({
                                ...prevAnnouncement,
                                expiryDate: newValue,
                            }));
                        }
                        } renderInput={(params) => <TextField {...params} />} />
                    </LocalizationProvider>

                    <div style={{ marginTop: '1rem' }}>
                        <div style={{ fontWeight: '500' }}>Upload File or Image</div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <input
                                style={{ display: 'none' }}
                                id="contained-button-file"
                                type="file"
                                onChange={handleSelectFile}
                            />
                            {!selectedFile &&
                                <label htmlFor="contained-button-file">
                                    <Button
                                        variant="contained"
                                        size="small"
                                        style={{ margin: '0.5rem', borderRadius: '15px' }}
                                        component="span"
                                        startIcon={<FileCopy />}
                                    >
                                        Upload
                                    </Button>
                                </label>
                            }
                            {selectedFile && (
                                <div style={{ marginLeft: '1rem' }}>
                                    {selectedFile.name}
                                    <Button
                                        variant="contained"
                                        size="small"
                                        style={{ margin: '0.5rem', borderRadius: '15px' }}
                                        onClick={() => setSelectedFile(null)}
                                    >
                                        Remove
                                    </Button>
                                </div>
                            )}
                        </div>
                    </div>






                    <div style={{ marginBottom: '1rem' }}>
                        <div style={{ fontWeight: '500' }}>Select Departments</div>
                        <Button
                            variant="contained"
                            size="small"
                            style={{  margin: '0.5rem', borderRadius: '15px' }}
                            onClick={handleSelectAllDepartments}
                        >
                            Select All
                        </Button>
                        <Button
                            variant="contained"
                            size="small"
                            style={{  margin: '0.5rem', borderRadius: '15px' }}
                            onClick={handleRemoveAllDepartments}
                        >
                            Remove All
                        </Button>
                        <Autocomplete
                            multiple
                            id="departments-select"
                            value={newAnnouncement.departments}
                            onChange={(_, newValue) => {
                                setNewAnnouncement((prevAnnouncement) => ({
                                    ...prevAnnouncement,
                                    departments: newValue,
                                }));
                            }}
                            options={departmentsList.map((dept) => dept.department)}
                            renderInput={(params) => <TextField {...params} variant="outlined" />}
                        />
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancel} color="primary">
                        Cancel
                    </Button>   
                    {creating ? (
                        <Button variant="contained">
                            <CircularProgress size={24} />
                        </Button>
                    ) : (
                        <Button variant="contained" onClick={handleCreateAnnouncement}>
                            Create
                        </Button>
                    )}
                </DialogActions>
            </Dialog>
            <Dialog open={fileDialog} onClose={() => setFileDialog(false)}>
                <DialogTitle>File</DialogTitle>
                <DialogContent>
                    <embed src={`${fileSrc}#toolbar=0`} style={{ width: '100%', height: '500px' }} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setFileDialog(false)} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

        </div>
    );
}

export default Announcements;
import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import { Button, useTheme } from "@mui/material";
import { withStyles } from "@material-ui/styles";
import AddIcon from "@mui/icons-material/Add";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InviteEmployee from "./InviteEmployee";
import ExportEmployeeDetails from "./ExportEmployeeDetails";


//custom style for input text field
const styles = (theme) => ({
  input: {
    height: 40,
    paddingRight: 0,
  },
});


const EmployeeHeader = withStyles(styles)((props) => {
  const { classes } = props;
  const [status, setStatus] = useState("All");
  const [search, setSearch] = useState("");
  const [allEmployees, setAllEmployees] = useState([]);
  const theme = useTheme();
  const handleChange = (event) => {
    setStatus(event.target.value);
    //set employess to filter by status
    if (event.target.value === "All") {
      props.setEmployees(props.mainEmployees);
    }
    else {
      props.setEmployees(props.mainEmployees.filter(employee => employee.flag.flag === event.target.value));
    }
  };
  const getActiveEmployeesCount = (employees) => {
    let count = 0;
    employees.forEach((employee) => {
      if (employee.flag.flag === "Active") {
        count++;
      }
    });
    return count;
  }

  //search function
  const searchHandler = (e) => {
    setSearch(e.target.value);
    props.setEmployeeLoading(true);
    if (e.target.value !== "") {
      let searchedEmployees = props.mainEmployees;
      searchedEmployees = searchedEmployees.filter(function (currentElement) {
        return (
          (currentElement.email &&
            currentElement.email
              .toLowerCase()
              .includes(e.target.value.toLowerCase())) ||
          (currentElement.employeeDetail.fname &&
            currentElement.employeeDetail.fname
              .toLowerCase()
              .includes(e.target.value.toLowerCase())) ||
          (currentElement.employeeDetail.lname &&
            currentElement.employeeDetail.lname
              .toLowerCase()
              .includes(e.target.value.toLowerCase()))
        );
      });
      props.setEmployeeLoading(false);
      props.setEmployees(searchedEmployees);
    } else {
      props.setEmployeeLoading(false);
      props.setEmployees(props.mainEmployees);
    }
  };

  return (
      <div
        className="header"
        style={{
          display: "flex",
          justifyContent: "space-between",
          backgroundColor: theme.palette.card.bg,
          border: "1px solid #ffffff66",
          borderRadius: "20px",
          padding: "1rem",
          flexDirection: "column",
          marginBottom: "0.5rem",
          borderCollapse: "separate",
          boxShadow: "0px 0px 10px 0px #0000003e",
        }}

      >


        <div style={{ display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center" }} >
          <div style={{ fontSize: "1.4rem", marginBottom: "5px" }}>Employees
            {
              <span style={{ fontSize: "1rem", fontWeight: "600", color: "#fff", backgroundColor: "green", padding: "0.2rem 0.5rem", borderRadius: "5px", marginLeft: "0.5rem" }}>
                {getActiveEmployeesCount(props.mainEmployees)} Active
              </span>
            }
          </div>
          <div style={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}>
            <div style={{ marginRight: "1rem" }}>
              {props.userInfo.isAdmin || props.userInfo.permissionData.Employee.read || props.userInfo.permissionData.Employee.write ?
                <InviteEmployee fetchEmployees={props.fetchEmployees} />
                : null
              }
            </div>
            {/* <div>
            <ExportEmployeeDetails data={props.mainEmployees} />
            </div> */}
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center" }} >
          <div>
            <div>Search by name/email</div>
            <TextField
              id="outlined-basic"
              style={{ width: "550px" }}
              sx={{ borderRadius: 2, backgroundColor: theme.palette.card.bg, color: theme.palette.text.primary, "& .MuiInputBase-input": { backgroundColor: theme.palette.card.alt, padding: "10px", borderRadius: "5px 0 0 5px" } }}
              value={search}
              onChange={searchHandler}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon sx={{ color: theme.palette.text.primary }} />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <Button
                      variant="contained"
                      style={{
                        borderRadius: "0 5px 5px 0",
                        textTransform: "capitalize",
                        fontWeight: "600",
                        position: "absolute",
                        right: "0",
                        height: "100%"
                      }}
                      onClick={searchHandler}
                    >
                      Search
                    </Button>
                  </InputAdornment>
                ),
              }}
              variant="outlined"
            />
          </div>
          <div>
            <div style={{ marginTop: "1.2rem" }}>Status</div>
            <FormControl size="small" variant="outlined" sx={{ width: "150px"}}>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={status}
                onChange={handleChange}
              >
                <MenuItem value={"All"}>All</MenuItem>
                <MenuItem value={"Active"}>Active</MenuItem>
                <MenuItem value={"Inactive"}>Inactive</MenuItem>
                <MenuItem value={"Onboarding"}>Onboarding</MenuItem>
                <MenuItem value={"Email Sent"}>Email Sent</MenuItem>
                <MenuItem value={"Registered"}>Registered</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
      </div>
  );
});

export default EmployeeHeader;

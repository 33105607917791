import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Typography,
  Grid,
  CircularProgress,
  Card,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CardContent,
  IconButton,

} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import moment from 'moment';
import axios from 'axios';
import ShiftTimeLine from '../components/Employees/Shifts/ShiftTimeLine';
import { AccessTime, Bolt, CalendarToday, HistoryToggleOff } from '@mui/icons-material';
import ErrorAlerts from '../components/ErrorAlert';
import { grey } from '@mui/material/colors';



const TimeLine = ({ id }) => {
  const [shifts, setShifts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [sortOption, setSortOption] = useState('desc');
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  const [summary, setSummary] = useState({
    totalShifts: 0,
    avgWorkingTimeWithoutBreak: '00:00',
    avgWorkingTimeFull: '00:00',
    avgBreakTime: '00:00',
    totalBreakTime: '00:00',
    totalWorkingTime: '00:00',
    totalShiftTime: '00:00',
  });


  const [error, setError] = useState(null);

  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${userInfo.JWT_TOKEN}`,
    },
  };

  const fetchShifts = (startDate, endDate) => {
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_BACKEND}/api/employee/shifts/${id}`, {
        ...config,
        params: { startDate, endDate },
      })
      .then((res) => {
        setLoading(false);
        const shiftsData = res.data.shifts.map((shift) => ({
          ...shift,
          startDate: moment(shift.startDate).isValid()
            ? moment(shift.startDate).local().format('DD-MM-YYYY')
            : 'N/A',
          startTime: moment(shift.startTime).isValid()
            ? moment.utc(shift.startTime).local().format('HH:mm')
            : 'N/A',
          endTime: moment(shift.endTime).isValid()
            ? moment.utc(shift.endTime).local().format('HH:mm')
            : 'N/A',
          breaks: shift.break || [],
          totalBreak: shift.totalBreak || '00:00',
          totalShiftLength: shift.totalShiftLength || '00:00',
          shiftWithoutBreak: shift.shiftWithoutBreak || '00:00',
        }));
        // Apply sorting locally after fetching the shifts
        const sortedShifts = sortShifts(shiftsData, sortOption);
        setShifts(sortedShifts);
        setSummary(res.data.summary);
      })
      .catch((err) => {
        setLoading(false);
        setError(err.response.data.message);
        setTimeout(() => {
          setError(null);
        }, 3000);
        console.error(err);
      });
  };

  // Function to sort shifts locally
  const sortShifts = (shifts, option) => {
    return shifts.sort((a, b) => {
      if (option === 'asc') {
        return moment(a.startDate, 'DD-MM-YYYY').diff(
          moment(b.startDate, 'DD-MM-YYYY')
        );
      } else {
        return moment(b.startDate, 'DD-MM-YYYY').diff(
          moment(a.startDate, 'DD-MM-YYYY')
        );
      }
    });
  };

  const handleSortChange = (e) => {
    const newSortOption = e.target.value;
    setSortOption(newSortOption);
    // Apply sorting locally whenever the sort option changes
    const sortedShifts = sortShifts([...shifts], newSortOption); // Sort a copy of the shifts array
    setShifts(sortedShifts);
  };

  const quickFetch = (days) => {
    setEndDate(moment().format('YYYY-MM-DD'));
    setStartDate(moment().subtract(days+1, 'days').format('YYYY-MM-DD'));
    fetchShifts(moment().subtract(days+1, 'days').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD'));
  };

  useEffect(() => {
    const today = moment().format('YYYY-MM-DD');
    const oneMonthBefore = moment().subtract(1, 'months').format('YYYY-MM-DD');
    setStartDate(oneMonthBefore);
    setEndDate(today);
  }, []);

  return (
    <Box sx={{ padding: 4, minHeight: '100vh' }}>
      {error && <ErrorAlerts error={error} />}
      <Box
        sx={{
          display: 'flex',
          gap: 3,
          marginBottom: 3,
          flexWrap: 'wrap',
          justifyContent: 'center',
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label="Start Date"
            value={startDate ? moment(startDate).toDate() : null}
            onChange={(newValue) => setStartDate(newValue)}
            renderInput={(params) => <input {...params} style={customInputStyle} />}
          />
          <DatePicker
            label="End Date"
            value={endDate ? moment(endDate).toDate() : null}
            onChange={(newValue) => setEndDate(newValue)}
            renderInput={(params) => <input {...params} style={customInputStyle} />}
          />
        </LocalizationProvider>
      </Box>
      <Box sx={{ textAlign: 'center', marginBottom: 4 }}>
        <Button
          variant="contained"
          onClick={() => fetchShifts(startDate, endDate)}
          sx={{
            padding: '10px 20px',
            textTransform: 'none',
            fontWeight: 'bold',
          }}
        >
          Fetch Shifts
        </Button>
      </Box>
      <Box sx={{
        textAlign: 'center',
        backgroundColor: '#898989bb',
        borderRadius: 3,
        margin: '0 auto',
        marginBottom: 3,
        boxShadow: 'inset 0px 0px 10px rgba(44, 44, 44, 0.619)',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        padding: '10px 4px',
        maxWidth: 'max-content',

      }}>
        <Button variant="contained"
          onClick={() => quickFetch(7)}
          sx={{ margin: "5px" }}>
          <Bolt /> Last 7 Days
        </Button>
        <Button variant="contained"
          onClick={() => quickFetch(30)}
          sx={{ margin: "5px" }}>
          <Bolt /> Last 30 Days
        </Button>
        <Button variant="contained"
          onClick={() => quickFetch(90)}
          sx={{ margin: "5px" }}>
          <Bolt /> Last 90 Days
        </Button>

      </Box>



      {/* Sort Option */}
      <Box sx={{ textAlign: 'center', marginBottom: 4 }}>
        <FormControl sx={{ marginBottom: 3 }}>
          <InputLabel>Sort By</InputLabel>
          <Select
            value={sortOption}
            label="Sort By"
            onChange={handleSortChange}
            sx={{
              borderRadius: 2,
              '.MuiOutlinedInput-notchedOutline': { borderColor: '#757575' },
            }}
          >
            <MenuItem value="asc">Date (Ascending)</MenuItem>
            <MenuItem value="desc">Date (Descending)</MenuItem>
          </Select>
        </FormControl>
      </Box>

      {loading ? (
        <Box sx={{ textAlign: 'center', marginTop: 4 }}>
          <CircularProgress color="primary" />
        </Box>
      ) : (

        <Grid container spacing={3}>
          {
            shifts.length === 0 ? (
              <Typography variant="h6" sx={{ textAlign: 'center', width: '100%' }}>
                No shifts
              </Typography>
            ) : (
              <>
                <Grid item xs={12}>

                  <Card
                    sx={{
                      borderRadius: 3,
                      maxWidth: 500,
                      boxShadow: 6,
                      overflow: 'hidden',
                      margin: '0 auto',
                      backgroundColor: '#212121',
                      color: '#fff'
                    }}
                  >
                    {/* Card Header */}
                    <CardContent sx={{ backgroundColor: '#3f51b5', padding: '24px', textAlign: 'center' }}>
                      <Typography variant="h5" sx={{ fontWeight: 'bold', letterSpacing: 1 }}>
                        Shift Summary
                      </Typography>
                      <Typography variant="subtitle1" sx={{ fontWeight: 300 }}>
                        A quick overview of your recent shifts
                      </Typography>
                    </CardContent>

                    {/* Card Body */}
                    <CardContent sx={{ padding: '16px 24px' }}>
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', backgroundColor: '#424242', padding: '12px 24px', borderRadius: 3 }}>
                            <Typography variant="body2" sx={{ fontWeight: 600 }}>Total Shifts</Typography>
                            <Typography variant="h6" sx={{ color: '#ff7043' }}>{summary.totalShifts}</Typography>
                          </Box>
                        </Grid>

                        <Grid item xs={6}>
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <AccessTime sx={{ color: '#ffb74d', marginRight: 1 }} />
                            <Typography variant="body2" sx={{ fontWeight: 600 }}>Avg Working Time (No Break)</Typography>
                          </Box>
                          <Typography variant="h6" sx={{ color: '#FFEB3B' }}>{summary.avgWorkingTimeWithoutBreak}</Typography>
                        </Grid>

                        <Grid item xs={6}>
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <AccessTime sx={{ color: '#ffb74d', marginRight: 1 }} />
                            <Typography variant="body2" sx={{ fontWeight: 600 }}>Avg Working Time (Full)</Typography>
                          </Box>
                          <Typography variant="h6" sx={{ color: '#FFEB3B' }}>{summary.avgWorkingTimeFull}</Typography>
                        </Grid>

                        <Grid item xs={6}>
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <HistoryToggleOff sx={{ color: '#81c784', marginRight: 1 }} />
                            <Typography variant="body2" sx={{ fontWeight: 600 }}>Avg Break Time</Typography>
                          </Box>
                          <Typography variant="h6" sx={{ color: '#66BB6A' }}>{summary.avgBreakTime}</Typography>
                        </Grid>

                        <Grid item xs={6}>
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <HistoryToggleOff sx={{ color: '#81c784', marginRight: 1 }} />
                            <Typography variant="body2" sx={{ fontWeight: 600 }}>Total Break Time</Typography>
                          </Box>
                          <Typography variant="h6" sx={{ color: '#66BB6A' }}>{summary.totalBreakTime}</Typography>
                        </Grid>

                        <Grid item xs={6}>
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <AccessTime sx={{ color: '#ffb74d', marginRight: 1 }} />
                            <Typography variant="body2" sx={{ fontWeight: 600 }}>Total Working Time</Typography>
                          </Box>
                          <Typography variant="h6" sx={{ color: '#FFEB3B' }}>{summary.totalWorkingTime}</Typography>
                        </Grid>

                        <Grid item xs={6}>
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <AccessTime sx={{ color: '#ffb74d', marginRight: 1 }} />
                            <Typography variant="body2" sx={{ fontWeight: 600 }}>Total Shift Time</Typography>
                          </Box>
                          <Typography variant="h6" sx={{ color: '#FFEB3B' }}>{summary.totalShiftTime}</Typography>
                        </Grid>
                      </Grid>
                    </CardContent>

                    {/* Card Footer */}
                    <CardContent sx={{ backgroundColor: '#424242', padding: '12px 24px', textAlign: 'center' }}>
                      <Typography variant="body2" sx={{ color: grey[500] }}>
                        *Summary is based on the selected date range only
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>

                {shifts.map((shift, index) => (
                  <Grid item xs={12} sm={6} md={4} key={index}>
                    <Card
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        border: `1px solid ${shift.approved ? '#4CAF50' : '#2196F3'}`,
                        borderLeft: `5px solid ${shift.approved ? '#4CAF50' : '#2196F3'}`,
                        padding: 2,
                        backgroundColor: `${shift.approved ? '#38653c5b' : '#26547648'}`,
                        borderRadius: '10px',
                        boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.433)',
                        position: 'relative',
                      }}
                    >
                      <Box
                        sx={{
                          position: 'absolute',
                          top: 10,
                          right: 10,
                          zIndex: 1,
                        }}
                      >
                        <ShiftTimeLine shiftTimelines={shift.shiftTimelines} showText={false} />
                      </Box>

                      <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: 1, display: 'flex', alignItems: 'center' }}>
                        <CalendarToday sx={{ marginRight: 1 }} />
                        {shift.startDate}
                      </Typography>
                      <Typography variant="body2">
                        <strong>Start:</strong> {shift.startTime}
                      </Typography>
                      <Typography variant="body2">
                        <strong>End:</strong> {shift.endTime}
                      </Typography>
                      <Typography variant="body2">
                        <strong>Total Shift:</strong> {shift.totalShiftLength}
                      </Typography>
                      <Typography variant="body2">
                        <strong>Break Time:</strong> {shift.totalBreak}
                      </Typography>
                      <Typography variant="body2">
                        <strong>Working Hours:</strong> {shift.shiftWithoutBreak}
                      </Typography>
                      <Typography variant="body2">
                        Shift ID: #{shift.id}
                      </Typography>
                      <Box>
                        <Typography variant="body2" sx={{ marginTop: 2, fontWeight: 'bold' }}>
                          Breaks:
                        </Typography>
                        {shift.breaks.length > 0 ? (
                          shift.breaks.map((br, brIndex) => (
                            <Typography
                              key={brIndex}
                              variant="body2"
                              sx={{
                                backgroundColor: '#1a1a1a99',
                                marginTop: 1,
                                borderRadius: '5px',
                                maxWidth: 'max-content',
                                marginLeft: 2,
                                padding: 1,
                                boxShadow: '0px 2px 5px rgba(17, 17, 17, 0.233)',
                              }}
                            >
                              {brIndex + 1}{")"} {moment(br.start).local().format('HH:mm')} - {moment(br.end).local().format('HH:mm')}
                            </Typography>
                          ))
                        ) : (
                          <Typography variant="body2" sx={{ paddingLeft: 2 }}>
                            No breaks recorded
                          </Typography>
                        )}

                      </Box>
                      <Typography
                        variant="body2"
                        sx={{
                          color: shift.approved ? '#4CAF50' : '#2196F3',
                          fontWeight: 'bold',
                          marginTop: 2,
                        }}
                      >
                        Status: {shift.approved ? 'Approved' : 'Pending'}
                      </Typography>
                    </Card>
                  </Grid>
                ))}
              </>
            )
          }
        </Grid>

      )}





    </Box>
  );
};

const customInputStyle = {
  padding: '10px',
  borderRadius: '8px',
  border: '1px solid #757575',
  backgroundColor: '#f9f9f9',
  color: '#000',
  width: '100%',
};

export default TimeLine;

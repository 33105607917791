import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';

export default function ShiftTimeLine(props) {
  const [open, setOpen] = React.useState(false);
  //sort props.shiftTimeline by createdAt
  const sortedShiftTimeline = props.shiftTimelines.sort((a, b) => {
    return new Date(a.createdAt) - new Date(b.createdAt);
  }
  );

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const formatTime = (date) => {
    let d = new Date(date);
    return d.toLocaleString()
  }
  return (
    <div>
      {
        props.showText !== null && props.showText === false ? <IconButton onClick={handleClickOpen} style={{
          backgroundColor: '#1a1a1a99',
          marginTop: 1,
          borderRadius: '5px',
          maxWidth: 'max-content',
          marginLeft: 2,
          padding: 3,
          boxShadow: '0px 2px 5px rgba(17, 17, 17, 0.233)',
        }}
        >
          <FormatListBulletedIcon />
        </IconButton> : <Button startIcon={<FormatListBulletedIcon />} style={{ textTransform: "capitalize", backgroundColor: "#e0e0e0", color: "#000", fontWeight: "500" }} variant="contained" onClick={handleClickOpen}>
          Shift Timeline
        </Button>


      }
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "500px",  // Set your width here
            },
          },
        }}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Shift Timeline
        </DialogTitle>
        <DialogContent >
          <Timeline style={{ marginLeft: "0" }}>
            {sortedShiftTimeline.map((s) => (


              <TimelineItem key={s.id}>
                <TimelineOppositeContent>
                  {formatTime(s.createdAt)}
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot color='primary' />
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>{s.message}</TimelineContent>
              </TimelineItem>
            ))
            }
          </Timeline>
        </DialogContent>
        <DialogActions >
          <Button style={{ margin: "auto", textTransform: "capitalize", fontWeight: "600" }} autoFocus onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
